<template>
    <div class="delete-agreement">
        <div class="bar">
            <div class="bar-title">删改指南</div>
            <div class="bar-line"></div>
        </div>
        <div class="content">
            <h4 class="title center">关于“预配舱单更改或删除”的处理指南</h4>
            <br />
            <div class="sub-title">
                船开前，未超过船代规定的舱单截止时间，且整批舱单都未报关：
            </div>
            <div class="flex flex-col">
                <span>
                    a）
                    如需更改船公司、船代、提单号、船名、航次、箱号、封号、品名、箱型：需在“已发送”中选中原舱单，提交删除舱单申请，待删除完成后，再重新提交新的舱单；其中箱型仅中联/东方海外/中外运/振华四家船代错了要删，其他可直接提交修改。
                </span>
                <span>
                    b）
                    如需更改除上述9类以外的信息，可在“已发送”中选中舱单，提交修改舱单申请，等待修改回执。
                </span>
                <span>c） 针对拼票（即拼箱）：</span>
                <div class="flex flex-col text-em">
                    <span>
                        i）如果需要修改其中某票或某几票信息，参考上述b点。
                    </span>
                    <span>
                        ii）如果需要删除其中的某票或某几票，需要把同箱号下的舱单全部删除后，再重新发送新的舱单
                    </span>
                    <span>
                        iii）如果需要添加1个拼票，可以直接发送该票拼票（船代为航华、鹏华时，加拼子单号必须和主单录在一起发送）
                    </span>
                </div>
            </div>
            <div class="sub-title">
                船开前，但已经超过船代规定的舱单截止时间，或者客户已报关：
            </div>
            <div class="flex flex-col">
                <span>a）凡是客户已报关的，必须先反核注报关</span>
                <span>
                    b）具体咨询对应船代，询问此事如何进行删改操作（具体可询问我司客服）
                </span>
                <span>
                    c）如已放行，需递送纸质文件至船代柜台办理取消“已放行”标志（具体可询问我司客服）
                </span>
            </div>
            <div class="sub-title">船开后，申请删单：</div>
            <div class="flex flex-col">
                <span>a）凡是客户已报关的，必须先注销报关单。</span>
                <span>
                    b）需递送纸质文件至船代柜台办理删单操作：一般来说，至少需要以下4种材料（仅供参考）：《删单申请书》、《报关单》、《删单情况说明》、《海关信息网上的通转状态查询页面的截图》（我司可提供代办服务，具体详询客服）
                </span>
            </div>
            <br />
            <br />
            <h4 class="title center">附：各家船代柜台服务地址</h4>
            <el-table
                width="1666px"
                :data="tableData"
                border
                class="agent-info-table"
            >
                <el-table-column
                    prop="name"
                    label="船代名称"
                    width="417"
                ></el-table-column>
                <el-table-column prop="address" label="地址"></el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
export default {
    name: "DeleteAgreement",
    data() {
        return {
            tableData: [
                {
                    name: "上海外代",
                    address: "虹口区吴淞路531号5楼",
                },
                {
                    name: "上海中外运",
                    address: "福建中路188号三楼大厅",
                },
                {
                    name: "上海联合",
                    address: "虹口区东大名路916号一楼",
                },
                {
                    name: "上海华港",
                    address: "东大名路501号1601室",
                },
                {
                    name: "上海顺德",
                    address: "海伦路440号2701室",
                },
                {
                    name: "上海航华",
                    address: "虹口区黄浦路99号2楼",
                },
                {
                    name: "上海民生",
                    address: "大连路1619号骏丰国际财富广场16楼",
                },
                {
                    name: "上海鹏华",
                    address: "杨浦区四平路1063号中天大厦1幢5楼501,502,508室",
                },
                {
                    name: "上海鹏海",
                    address: "延安东路700号1807-1809室",
                },
                {
                    name: "上海中联（中和）",
                    address: "虹口区东大名路908号24楼",
                },
                {
                    name: "上海振华",
                    address: "延安东路700号港泰广场21楼",
                },
                {
                    name: "新海",
                    address: "虹口区天潼路133号上海家化金融大厦8楼",
                },
                {
                    name: "锦诚",
                    address: "延安东路1200号8楼",
                },
                {
                    name: "东方海外",
                    address: "延安中路841号20楼",
                },
            ],
        };
    },
};
</script>
<style lang="stylus">
.agent-info-table{  
    .el-table__header-wrapper th{
        background-color: #e5e7eb !important;
        font-size:12px;
        color:#282828;
        font-weight: 700;
    }
    .el-table__cell{
        padding:7px 0;
        .cell{
            font-size:12px;
            color:#4b5669;
        }
    }        
}
</style>
<style scoped lang="stylus">
.delete-agreement{
    margin:16px 0;
    background-color: #fff;
    padding:12px;
    .bar{
        .bar-title{
            color:rgb(40,40,40);
            font-weight: 700;
            font-size:15.75px;
            padding:6px 8px 16px;
            line-height:24.5px;
        }
        .bar-line{
            height:1px;
            background-color:rgb(229,229,229);
        }
    }
    .content{
        padding:17.5px;
        line-height:2;
        font-size:14px;
        color:#282828;
        p, span, div{
            word-wrap: break-word;
            word-break: break-word;
            hyphens: auto;
        }
        .center{
            text-align:center;
        }
        .title{
            color:#282828;
            font-size:14px;
        }
        .sub-title{
            margin-top: 10px;
            margin-bottom: 10px;
            border-width: 0px;
            border-left-width: 3px;
            border-style: solid;
            border-color: rgb(255,152,7);
            text-indent: 10px;
            font-size: 14px;
            font-weight: 700;
            line-height: 1.5;
            color: rgb(255,152,7);
        }
        .flex{
            display:flex;
        }
        .flex-col{
            flex-direction:column;
        }
        .text-em{
            text-indent: 2em;
        }
    }
}
</style>
